<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <v-card flat>
        <v-card-title>User edit
            <v-layout justify-end>
                <v-btn color="primary" outlined class="ml-4" @click="close">cancel</v-btn>
                <v-btn color="primary" class="ml-4" @click="save">save</v-btn>
            </v-layout>
        </v-card-title>
        <v-card-text>
            <v-row>
                <v-col cols="12" md="6">
                    <v-card elevation="0" class="my-5">
                        <v-card-title>
                            Main data
                        </v-card-title>
                        <v-card-text class="mt-4">
                            <TextField label="Name" v-model="name"></TextField>
                            <TextField
                                label="Login"
                                v-model="login"
                                autocomplete="off" />
                            <TextField
                                v-model="password"
                                slotName="append"
                                name="password"
                                :type="passwordFieldType"
                                :label="$t('labels.password')"
                                autocomplete="off"
                            >
                                <template slot="slotContent">
                                    <v-icon
                                        @click="togglePassword"
                                        size="20"
                                        color="medium_grey"
                                    >{{ passwordFieldIcon }}</v-icon
                                    >
                                </template>
                            </TextField>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col cols="12" md="6">
                    <v-row>
                        <v-col cols="12">
                            <v-card elevation="0">
                                <v-card-title>
                                </v-card-title>
                                <v-card-text class="mt-4">
                                    <v-checkbox v-for="(item) in defaultPermissions" v-model="permissions"
                                              :value="item.id"
                                              :key="item.id"
                                              class="d-flex"
                                              :label="$t(`superadmin.${item.short_name}`)">
                                    </v-checkbox>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>
import { mapFields } from 'vuex-map-fields';
import TextField from '@/components/widgets/forms/TextField';
import { ICONS } from '@/constants/icons';

export default {
    name: 'SuperAdminManageUserForm',
    components: { TextField },
    data: () => ({
        passwordFieldType: 'password',
        passwordFieldIcon: ICONS.DETAILS,
        securityIcon: ICONS.SECURITY
    }),
    computed: {
        ...mapFields('superadmin', [
            'user.id',
            'user.name',
            'user.login',
            'user.password',
            'user.permissions',
            'allPermissions'
        ]),
        defaultPermissions () {
            return this.allPermissions.filter(e => e.short_name !== 'selected_agency');
        }
    },
    methods: {
        togglePassword () {
            if (this.passwordFieldType === 'password') {
                this.passwordFieldType = 'text';
                this.passwordFieldIcon = ICONS.DETAILS_OFF;
            } else {
                this.passwordFieldType = 'password';
                this.passwordFieldIcon = ICONS.DETAILS;
            }
        },
        close () {
            this.$emit('close');
        },
        save () {
            if (this.id) {
                this.$emit('save');
            } else {
                this.$emit('create');
            }
        }
    }

};
</script>
