import { render, staticRenderFns } from "./SuperAdminUsers.vue?vue&type=template&id=180d0f6e&scoped=true&xmlns%3Av-slot=http%3A%2F%2Fwww.w3.org%2F1999%2FXSL%2FTransform&"
import script from "./SuperAdminUsers.vue?vue&type=script&lang=js&"
export * from "./SuperAdminUsers.vue?vue&type=script&lang=js&"
import style0 from "./SuperAdminUsers.vue?vue&type=style&index=0&id=180d0f6e&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "180d0f6e",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSheet } from 'vuetify/lib/components/VSheet';
installComponents(component, {VDialog,VProgressCircular,VSheet})
