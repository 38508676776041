<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div>
        <DefaultTable v-if="canRead"
            ref="usersTable"
            :setup="tableSetup"
            @delete-item="deleteItem($event)"
            @edit-item="openForm($event)">
        </DefaultTable>
        <div v-else class="d-flex justify-center align-center h-full">
            <h2>You are not authorized to view this page</h2>
        </div>
        <v-dialog v-model="dialog" :persistent="true" max-width="1000px">
            <v-sheet outlined height="68vh" class="d-flex justify-center align-center" v-if="loader">
                <v-progress-circular
                    :size="120"
                    :width="7"
                    indeterminate
                >loading</v-progress-circular>
            </v-sheet>
            <SuperAdminManageUserForm v-if="!loader"
                @save="saveItem"
                @create="createItem"
                @close="closeForm">
            </SuperAdminManageUserForm>
        </v-dialog>
    </div>
</template>

<script>
import DefaultTable from '@/components/DefaultTable';
import SuperAdminManageUserForm from '@/components/superadmin/SuperAdminManageUserForm';

export default {
    name: 'SuperAdminUsers',
    components: { DefaultTable, SuperAdminManageUserForm },
    data: () => ({
        dialog: false,
        loader: false
    }),
    computed: {
        canRead () {
            return this.$can(this.$abilityActions.MANAGE, this.$abilitySubjects.SUPER_ADMIN_USER);
        },
        tableSetup () {
            return {
                title: 'Users',
                searchBy: 'name',
                headers: [
                    {
                        text: 'No',
                        value: 'seq',
                        sortable: false
                    },
                    {
                        text: 'id',
                        value: 'id',
                        sortable: true
                    },
                    {
                        text: 'User name',
                        value: 'name',
                        sortable: true
                    },
                    {
                        text: 'Login',
                        value: 'login',
                        sortable: true
                    },
                    {
                        text: 'Operations',
                        value: 'actions',
                        align: 'end',
                        width: '100px',
                        sortable: false
                    }
                ],
                fetchParams: null,
                endpoint: 'superadmin_users',
                ability: {
                    c: true,
                    r: false,
                    u: true,
                    d: true
                },
                sortable: {
                    id: 'desc',
                    name: 'asc',
                    login: 'asc'
                }
            };
        }
    },
    methods: {
        openForm (item) {
            if (item) {
                this.$store.dispatch('superadmin/getUser', item.id);
            }
            this.dialog = true;
        },
        closeForm () {
            this.dialog = false;
            this.loader = false;
            this.$store.commit('superadmin/SET_DEFAULT_USER');
            this.$refs.usersTable.fetchData();
        },
        createItem () {
            this.loader = true;
            this.$store.dispatch('superadmin/createUser').then(() => {
                this.closeForm();
            });
        },
        saveItem () {
            this.loader = true;
            this.$store.dispatch('superadmin/updateUser').then(() => {
                this.closeForm();
            });
        },
        deleteItem (item) {
            this.loader = true;
            this.$store.dispatch('superadmin/deleteUser', item.id).then(() => {
                this.closeForm();
            });
        }
    },
    created () {
        if (this.canRead) {
            this.$store.dispatch('superadmin/getPermissions');
        }
    }
};
</script>
<style scoped>
    .h-full {
        height: 100vh;
    }
</style>
